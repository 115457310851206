/* eslint no-console:0 */

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

import "core-js/stable"
import "regenerator-runtime/runtime"

// images
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// styles
import "icomoon/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'simple-line-icons'
import "../stylesheets/application.scss"

// JS
// import "jquery";
// import "bootstrap";

require("@rails/ujs").start();

// require("@rails/activestorage").start();
import * as ActiveStorage from "@rails/activestorage";

import MainPoint from "../js/MainPoint";

export const onDocumentLoaded = () => {
  $("a[rel~=popover], .has-popover").popover()
  $("a[rel~=tooltip], .has-tooltip, [data-toggle=\"tooltip\"]").tooltip()

  const mainPoint = new MainPoint();
  mainPoint.initialize();
  window.mainPoint = mainPoint;
  ActiveStorage.start();
};

// export to Pack
export { default as GalleryForm } from '../js/pages/GalleryForm'
export { default as DropZone } from '../js/pages/DropZone'
export { initAvatarCircles as initAvatarCircles } from '../js/pages/GalleryAvatars'
export { bindMakeDefault as bindMakeDefault } from '../js/pages/PictureForm'
