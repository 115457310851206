export const offCanvass = () => {
  $('body').on('click', '.js-fh5co-nav-toggle', (e) => {
    const $this = $(e.target);
    $('#fh5co-offcanvass').toggleClass('fh5co-awake');
    $('#fh5co-page, #fh5co-menu').toggleClass('fh5co-sleep');
    if ( $('#fh5co-offcanvass').hasClass('fh5co-awake') ) {
      $this.addClass('active');
    } else {
      $this.removeClass('active');
    }
    e.preventDefault();
  });
};

export const clickMenu = () => {
  $('a:not([class="external"])').click((e) => {
    const section = $(e.target).data('nav-section');
    if (section != null) {
      $('html, body').animate({
        scrollTop: $('[data-section="' + section + '"]').offset().top
      }, 500);
      return false;
    }
    return e;
  });
};

export const carouselTestimony = () => {
  const owl = $(".owl-carousel");
  owl.owlCarousel({
    items: 1,
    margin: 0,
    responsiveClass: true,
    loop: true,
    nav: true,
    dots: true,
    autoplay: true,
    smartSpeed: 500,
    responsive:{
      0:{
        nav:false
      },
      480: {
        nav:false
      },
      768:{
        nav:false
      },
      1000:{
        nav:true,
      }
    },
    navText: [
      "<i class='icon-arrow-left owl-direction'></i>",
      "<i class='icon-arrow-right owl-direction'></i>"
    ]
  });
};

export const footerFixed = () => {
  const fh = $('#fh5co-footer').innerHeight();
  $('#fh5co-wrap').css({
    marginBottom : fh + 'px'
  });

  if ( $(window).width() < 991 ) {
    $('#fh5co-wrap').css({
      marginBottom: ''
    })
  }

  $(window).resize(() => {

    const fh = $('#fh5co-footer').innerHeight();
    $('#fh5co-wrap').css({
      marginBottom : fh + 'px'
    });

    if ( $(window).width() < 991 ) {
      $('#fh5co-wrap').css({
        marginBottom: ''
      })
    }
  });
};

export const counter = () => {
  $('.js-counter').countTo({
    formatter: (value, options) => value.toFixed(options.decimals),
  });
};

export const faqsAccordion = () => {
  const faqAcc = $('.faq-accordion h3');

  faqAcc.on('click', (event) => {
    const $this = $(event.target);

    $('.faq-accordion').removeClass('active');
    $('.faq-accordion').find('.faq-body').slideUp(400, 'easeInOutExpo');

    if ( !$this.closest('.faq-accordion').find('.faq-body').is(':visible')) {
      $this.closest('.faq-accordion').addClass('active');
      $this.closest('.faq-accordion').find('.faq-body').slideDown(400, 'easeInOutExpo');
    } else {
      $this.closest('.faq-accordion').removeClass('active');
      $this.closest('.faq-accordion').find('.faq-body').slideUp(400, 'easeInOutExpo');
    }


    setTimeout(() => {
      // alert($this.closest('.faq-accordion.active').innerHeight());
      $('html, body').animate({
        scrollTop: $this.closest('.faq-accordion.active').offset().top - 90
      }, 500);
    }, 700);


    event.preventDefault();
    return false;
  });
};

export const mobileMenuOutsideClick = () => {
  $(document).click(e => {
    const container = $("#fh5co-offcanvass, .js-fh5co-nav-toggle");
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      if ( $('#fh5co-offcanvass').hasClass('fh5co-awake') ) {
        $('#fh5co-offcanvass').removeClass('fh5co-awake');
        $('#fh5co-page, #fh5co-menu').removeClass('fh5co-sleep');

        $('.js-fh5co-nav-toggle').removeClass('active');
      }
    }
  });

  $(window).scroll(() => {
    const $menu = $('#fh5co-menu');
    if ( $(window).scrollTop() > 150 ) {
      $menu.addClass('sleep');
    }

    if ( $(window).scrollTop() < 500 ) {
      $menu.removeClass('sleep');
      $('#fh5co-offcanvass ul li').removeClass('active');
      $('#fh5co-offcanvass ul li').first().addClass('active');
    }


    if ( $(window).scrollTop() > 500 ) {
      if ( $('#fh5co-offcanvass').hasClass('fh5co-awake') ) {
        $('#fh5co-offcanvass').removeClass('fh5co-awake');
        $('#fh5co-page, #fh5co-menu').removeClass('fh5co-sleep');

        $('.js-fh5co-nav-toggle').removeClass('active');
      }
    }
  });
};

export const magnifPopup = () => {
  $('.image-popup').magnificPopup({
    type: 'image',
    removalDelay: 300,
    mainClass: 'mfp-with-zoom',
    titleSrc: 'title',
    gallery:{
      enabled:true
    },
    zoom: {
      enabled: true, // By default it's false, so don't forget to enable it

      duration: 300, // duration of the effect, in milliseconds
      easing: 'ease-in-out', // CSS transition easing function

      // The "opener" function should return the element from which popup will be zoomed in
      // and to which popup will be scaled down
      // By defailt it looks for an image tag:
      opener: openerElement => openerElement.is('img') ? openerElement : openerElement.find('img')
    }
  });
};

export const introWayPoint = () => {
  if ( $('#fh5co-hero').length > 0 ) {
    $('#fh5co-hero').waypoint((direction) => {

      if( direction === 'down' && !$(this).hasClass('animated') ) {

        setTimeout(() => {
          $('.intro-animate-1').addClass('fadeInUp animated');
        }, 100);
        setTimeout(() => {
          $('.intro-animate-2').addClass('fadeInUp animated');
        }, 400);
        setTimeout(() => {
          $('.intro-animate-3').addClass('fadeInUp animated');
          $('.intro-animate-4').addClass('fadeInUp animated');
        }, 700);

        $(this.element).addClass('animated');
      }
    } , { offset: '75%' } );
  }
};

export const HeaderToggle = () => {
  const $this = $( '#fh5co-main' );
  $this.waypoint(direction => {
    if( direction === 'down' ) {
      $('body').addClass('scrolled');
    }
    else if( direction === 'up' ){
      $('body').removeClass('scrolled');
    }
  }, { offset: '-1px' } );
};

export const clientAnimate = () => {
  if ( $('#fh5co-clients').length > 0 ) {
    $('#fh5co-clients .to-animate').each((k) => {
      const el = $(this);
      setTimeout (() => {
        el.addClass('fadeIn animated');
      },  k * 200, 'easeInOutExpo' );
    });
  }
};
export const clientWayPoint = () => {
  if ( $('#fh5co-clients').length > 0 ) {
    $('#fh5co-clients').waypoint((direction) => {
      if( direction === 'down' && !$(this).hasClass('animated') ) {
        setTimeout(clientAnimate, 100);
        $(this.element).addClass('animated');
      }
    } , { offset: '75%' } );
  }
};

export const featuresAnimate = () => {
  if ( $('#fh5co-features').length > 0 ) {
    $('#fh5co-features .to-animate').each((k) => {
      const el = $(this);
      setTimeout (() => {
        el.addClass('fadeInUp animated');
      },  k * 200, 'easeInOutExpo' );
    });
  }
};
export const featuresWayPoint = () => {
  if ( $('#fh5co-features').length > 0 ) {
    $('#fh5co-features').waypoint((direction) => {
      if( direction === 'down' && !$(this).hasClass('animated') ) {
        setTimeout(featuresAnimate, 100);
        $(this.element).addClass('animated');
      }
    } , { offset: '75%' } );
  }
};

export const features2AnimateTitle = () => {
  if ( $('#fh5co-features-2').length > 0 ) {
    $('#fh5co-features-2 .to-animate').each((k) => {
      const el = $(this);
      setTimeout ( function () {
        el.addClass('fadeIn animated');
      },  k * 200, 'easeInOutExpo' );
    });
  }
};
export const features2WayPoint = () => {
  if ( $('#fh5co-features-2').length > 0 ) {
    $('#fh5co-features-2').waypoint((direction) => {
      if( direction === 'down' && !$(this).hasClass('animated') ) {
        setTimeout(features2AnimateTitle, 100);
        setTimeout(() => {
          $('.features-2-animate-2').addClass('fadeInUp animated');
        }, 800);
        setTimeout(() => {
          $('.features-2-animate-3').addClass('fadeInRight animated');
          $('.features-2-animate-5').addClass('fadeInLeft animated');
        }, 1200);
        setTimeout(() => {
          $('.features-2-animate-4').addClass('fadeInRight animated');
          $('.features-2-animate-6').addClass('fadeInLeft animated');
        }, 1400);
        $(this.element).addClass('animated');
      }
    } , { offset: '75%' } );
  }
};

export const counterWayPoint = () => {
  if ( $('#fh5co-counter').length > 0 ) {
    $('#fh5co-counter').waypoint((direction) => {
      if( direction === 'down' && !$(this).hasClass('animated') ) {
        setTimeout(() => {
          $('.counter-animate').addClass('fadeInUp animated');
          counter();
        }, 100);
        $(this.element).addClass('animated');
      }
    } , { offset: '75%' } );
  }
};

export const productsAnimate = () => {
  if ( $('#fh5co-products').length > 0 ) {
    $('#fh5co-products .to-animate').each((k) => {
      const el = $(this);
      setTimeout (() => {
        el.addClass('fadeInUp animated');
      },  k * 200, 'easeInOutExpo' );
    });
  }
};
export const productsWayPoint = () => {
  if ( $('#fh5co-products').length > 0 ) {
    $('#fh5co-products').waypoint((direction) => {
      if( direction === 'down' && !$(this).hasClass('animated') ) {
        setTimeout(() => {
          $('.product-animate-1').addClass('fadeIn animated');
        }, 200);
        setTimeout(() => {
          $('.product-animate-2').addClass('fadeIn animated');
        }, 400);
        setTimeout(productsAnimate, 800);
        $(this.element).addClass('animated');
      }
    } , { offset: '75%' } );
  }
};

export const ctaAnimate = () => {
  if ( $('#fh5co-cta').length > 0 ) {
    $('#fh5co-cta .to-animate').each((k) => {
      const el = $(this);
      setTimeout ( function () {
        el.addClass('fadeInUp animated');
      },  k * 200, 'easeInOutExpo' );
    });
  }
};
export const ctaWayPoint = () => {
  if ( $('#fh5co-cta').length > 0 ) {
    $('#fh5co-cta').waypoint((direction) => {
      if( direction === 'down' && !$(this).hasClass('animated') ) {
        setTimeout(ctaAnimate, 100);
        $(this.element).addClass('animated');
      }
    } , { offset: '75%' } );
  }
};

export const pricingAnimate = () => {
  if ( $('#fh5co-pricing').length > 0 ) {
    $('#fh5co-pricing .to-animate').each((k) => {
      const el = $(this);
      setTimeout ( function () {
        el.addClass('fadeInUp animated');
      },  k * 200, 'easeInOutExpo' );
    });
  }
};
export const pricingWayPoint = () => {
  if ( $('#fh5co-pricing').length > 0 ) {
    $('#fh5co-pricing').waypoint((direction) => {
      if( direction === 'down' && !$(this).hasClass('animated') ) {
        setTimeout(() => {
          $('.pricing-animate-1').addClass('fadeInUp animated');
        }, 100);
        setTimeout(() => {
          $('.pricing-animate-2').addClass('fadeInUp animated');
        }, 400);
        setTimeout(pricingAnimate, 800);
        $(this.element).addClass('animated');
      }
    } , { offset: '75%' } );
  }
};

export const features3Animate = () => {
  if ( $('#fh5co-features-3').length > 0 ) {
    $('#fh5co-features-3 .to-animate').each((k) => {
      const el = $(this);
      setTimeout (() => {
        el.addClass('fadeInUp animated');
      },  k * 200, 'easeInOutExpo' );
    });
  }
};
export const features3WayPoint = () => {
  if ( $('#fh5co-features-3').length > 0 ) {
    $('#fh5co-features-3').waypoint((direction) => {
      if( direction === 'down' && !$(this).hasClass('animated') ) {
        setTimeout(() => {
          $('.features3-animate-1').addClass('fadeIn animated');
        }, 200);
        setTimeout(() => {
          $('.features3-animate-2').addClass('fadeIn animated');
        }, 400);
        setTimeout(features3Animate, 800);
        $(this.element).addClass('animated');
      }
    } , { offset: '75%' } );
  }
};

export const faqsAnimate = () => {
  if ( $('#fh5co-faqs').length > 0 ) {
    $('#fh5co-faqs .to-animate').each((k) => {
      const el = $(this);
      setTimeout (() => {
        el.addClass('fadeInUp animated');
      },  k * 200, 'easeInOutExpo' );
    });
  }
};
export const faqsWayPoint = () => {
  if ( $('#fh5co-faqs').length > 0 ) {
    $('#fh5co-faqs').waypoint((direction) => {
      if( direction === 'down' && !$(this).hasClass('animated') ) {
        setTimeout(() => {
          $('.faqs-animate-1').addClass('fadeIn animated');
        }, 200);
        setTimeout(() => {
          $('.faqs-animate-2').addClass('fadeIn animated');
        }, 400);
        setTimeout(faqsAnimate, 800);
        $(this.element).addClass('animated');
      }
    } , { offset: '75%' } );
  }
};

export const contentWayPoint = () => {
  $('.animate-box').waypoint((direction) => {
    if( direction === 'down' && !$(this).hasClass('animated') ) {
      $(this.element).addClass('fadeInUp animated');
    }
  } , { offset: '75%' } );
};

export const navActive = section => {
  const el = $('#fh5co-offcanvass > ul');
  el.find('li').removeClass('active');
  el.each(() => {
    $(this).find('a[data-nav-section="'+section+'"]').closest('li').addClass('active');
  });
};
export const navigationSection = () => {
  const $section = $('div[data-section]');
  $section.waypoint((direction) => {
    if (direction === 'down') {
      navActive($(this.element).data('section'));
    }
  }, {
    offset: '150px'
  });

  $section.waypoint((direction) => {
    if (direction === 'up') {
      navActive($(this.element).data('section'));
    }
  }, {
    offset: () => -$(this.element).height() + 155
  });
};
