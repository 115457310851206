class DropZone {
  constructor(dropZoneId= "drop-zone", buttonId= "clickHere", mouseOverClass= "mouse-over") {
    this.dropZoneId = dropZoneId
    this.buttonId = buttonId
    this.mouseOverClass = mouseOverClass
    this._initElements();
    this._bindEvents();
  }

  // private
  _initElements() {
    this.dropZone = $(`#${this.dropZoneId}`);
    this.ooleft = this.dropZone.offset().left;
    this.ooright = this.dropZone.outerWidth() + this.ooleft;
    this.ootop = this.dropZone.offset().top;
    this.oobottom = this.dropZone.outerHeight() + this.ootop;
    this.inputFile = this.dropZone.find("input");
  }

  _bindEvents() {
    document.getElementById(this.dropZoneId).addEventListener("dragover", this._onDragOver, true);
    this._bindButtonEvents();
    document.getElementById(this.dropZoneId).addEventListener("drop", this._onDrop, true);
  }

  _bindButtonEvents() {
    if (this.buttonId == "") return;

    const clickZone = $(`#${this.buttonId}`);
    const oleft = clickZone.offset().left;
    const oright = clickZone.outerWidth() + oleft;
    const otop = clickZone.offset().top;
    const obottom = clickZone.outerHeight() + otop;

    clickZone.on('mousemove', (e) => {
      const x = e.pageX;
      const y = e.pageY;
      if (!(x < oleft || x > oright || y < otop || y > obottom)) {
        this.inputFile.offset({ top: y - 15, left: x - 100 });
      } else {
        this.inputFile.offset({ top: 400, left: 400 });
      }
    });
  }

  // private callbacks
  _onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.dropZone.addClass(this.mouseOverClass);
    const x = e.pageX;
    const y = e.pageY;

    if (!(x < this.ooleft || x > this.ooright || y < this.ootop || y > this.oobottom)) {
      this.inputFile.offset({ top: y - 15, left: x - 100 });
    } else {
      this.inputFile.offset({ top: -400, left: -400 });
    }
  }

  _onDrop = (_e) => {
    $(`#${this.dropZoneId}`).removeClass(this.mouseOverClass);
  }
}
export default DropZone;
