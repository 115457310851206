const onRotate = (e) => {
  $('#pictures').easyOverlay('start');
  $('.image a, input[type="submit"]').attr('disabled', 'disabled');
  return e;
}

function onLoadXHhr(e) {
  if (this.status == 200) {
    document.location.href = this.responseURL;
  } else {
    document.write(this.response);
  }
}

const bindDirectUploadsComplete = (id) => {
  const element = document.getElementById(`container-file-upload-${id}`);
  element.querySelector('.delete-new-file').dataset.uploadInProgress = '';
  const $form = $(element).closest('form');
  const uploadsInProgress = $form.find('[data-upload-in-progress="true"]').length;
  if (!uploadsInProgress) {
    $form.find('.important-fact-save').prop('disabled', false);
  }
}

class GalleryForm {
  filesList = [];

  initFiles() {
    document.getElementById('pictureInput').addEventListener('change', this._handleFileSelect, false);
    $(document).on('click', '.remove-image', this._onRemoveImage);
    $('.rotate-link').on('ajax:beforeSend', onRotate);
    $(document).find('form.form-horizontal').on('submit', this._onSubmitForm);
  }

  init() {
    this.initFiles();
    this._bindDirectUploads()
  }

  _onRemoveImage = (e) => {
    e.preventDefault();
    const name = $(e.target).data('name');
    this.filesList = this.filesList.filter((file) => file.name !== name);
    $(e.target).closest('span').remove();
    return false;
  }

  _onSubmitForm = (e) => {
    $('#fh5co-page').easyOverlay('start');
    $('.image a, input[type="submit"]').attr('disabled', 'disabled');
    return e;

    // e.preventDefault();
    // setTimeout(() => {
    //   $('#pictureInput').remove();
    //   const form = $(document).find('form.form-horizontal')[0];
    //   const data = new FormData(form);
    //
    //   for (let i = 0, len = this.filesList.length; i < len; i++) {
    //     data.append('images[]', this.filesList[i], this.filesList[i].name);
    //   }
    //
    //   const xhr = new XMLHttpRequest();
    //   xhr.open('POST', $(form).attr('action'), true);
    //   // xhr.setRequestHeader('Content-Type', 'multipart/form-data');
    //   xhr.onload = onLoadXHhr;
    //   xhr.send(data);
    // }, 300);
    // return false;
  }

  _handleFileSelect = (evt) => {
    const files = evt.target.files; // FileList object

    // Loop through the FileList and render image files as thumbnails.
    for (let i = 0, f; f = files[i]; i++) {

      // Only process image files.
      if (!f.type.match('image.*')) { continue; }

      const reader = new FileReader();

      // Closure to capture the file information.
      reader.onload = (theFile => (e) => {
        this.filesList.push(theFile);

        // Render thumbnail.
        const span = document.createElement('span');
        span.innerHTML = [
          '<img class="thumb" src="', e.target.result,
          '" title="',
          escape(theFile.name),
          '"/>',
          '<a href="#remove" class="remove-image" data-name="',
          escape(theFile.name),
          '"><span class="glyphicon glyphicon-remove pic-remove" data-toggle="tooltip" data-placement="top" title="Remove Photo"></span></a>'
        ].join('');
        document.getElementById('list').insertBefore(span, null);
        $('#list [data-toggle="tooltip"]').tooltip();
      })(f);

      // Read in the image file as a data URL.
      reader.readAsDataURL(f);
    }
  }

  _bindDirectUploads = () => {
    window.addEventListener("direct-upload:initialize", this._initDirectUpload);
    window.addEventListener("direct-upload:progress", this._progressDirectUpload);
    window.addEventListener("direct-upload:error", this._errorDirectUpload);
    window.addEventListener("direct-upload:end", this._completeDirectUpload);
    ['direct-upload:before-blob-request', 'direct-upload:before-storage-request', 'direct-upload:start'].forEach(item => {
      window.addEventListener(item, this._onStartUploadEvents)
    });
  }

  _initDirectUpload = (event) => {
    const { target, detail } = event;
    const { id, file } = detail;
    $(target).closest('form').find('input[type="submit"]').prop('disabled', 'disabled');

  //   target.parentElement.insertAdjacentHTML("beforebegin", `
  //   <div id="container-file-upload-${id}" class="file-upload-collapsable w-100">
  //     <div class="attached-file clearfix">
  //       <div class="attachment-name">
  //         <span>${file.name}</span>
  //         <div class="notation-font">&nbsp;(${file.size})</div>
  //       </div>
  //       <div class="icon-btn icon-minus delete-new-file" id="delete-file-${id}" data-upload-in-progress="true" data-direct-upload-id="${id}" data-blob-id=""></div>
  //     </div>
  //     <div id="direct-upload-${id}"></div>
  //     <div class="progress-line">
  //       <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
  //       <div class="direct-upload__progress_grey" style="width: 100%"></div>
  //     </div>
  //   </div>
  // `);
  }
  _progressDirectUpload = event => {
    console.log('_progressDirectUpload')
    const { id, progress } = event.detail;
    // const progressElement = document.getElementById(`direct-upload-progress-${id}`);
    // if (progressElement) progressElement.style.width = `${progress}%`
  }
  _errorDirectUpload = event => {
    console.log('_errorDirectUpload')
    console.log(event)
    event.preventDefault();
    const { id, error } = event.detail;
    // const element = document.getElementById(`direct-upload-${id}`);
    // element.setAttribute("title", error)
  }
  _completeDirectUpload = event => {
    console.log('_completeDirectUpload')
    const { id } = event.detail;
    // bindDirectUploadsComplete(id)
  }
  _onStartUploadEvents = event => {
    console.log('_onStartUploadEvents')
    let {id, xhr} = event.detail;
    $(document).off('click', `#delete-file-${id}`).on('click', `#delete-file-${id}`, () => {
      if (xhr) xhr.abort();
      bindDirectUploadsComplete(id)
    })
  }
}
export default GalleryForm;
